import logo from './logo.svg';
import { ReactSVG } from 'react-svg';
import wave from './assets/wave.svg';
import dots from './assets/dots.svg';
import { logDOM } from '@testing-library/react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={dots} className="App-logo" alt="logo" />
        <div className="Progress"><div className="Bar"></div></div>
              
      </header>
    </div>
  );
}

export default App;
